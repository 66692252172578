<template>
  <iframe :src="iframeURL" width="100%" height="100%" frameborder="0" scrolling="yes"></iframe>
</template>

<script>
export default {
  name: '',
  data () {
    return {
      iframeURL: this.$route.meta.iframeURL,
    }
  },
  mounted () {

  },
  methods: {},
}
</script>

<style scoped></style>
